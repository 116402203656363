import React, { useEffect, useState } from 'react'
import trading from "../images/trading.gif"
import categoriesimg1 from "../images/categories-img1.jpg"
import categoriesimg2 from "../images/categories-img2.jpg"
import categoriesimg3 from "../images/categories-img3.jpg"
import categoriesimg4 from "../images/categories-img4.jpg"
import categoriesimg5 from "../images/categories-img5.jpg"
import categoriesimg6 from "../images/categories-img6.jpg"
import enrolledimg2 from "../images/enrolled-img2.png"
import broadCastIco from '../images/broadCastIco.png';
import monthlyGroup from "../images/monthlyGroup.jpeg"
import EducationalVideos from './edcationVideos';
import homeVideo from '../assets/video/homeVideo.mp4';
import monthlyVideo from '../assets/video/monthlyVideo.mp4';
import Testimonials from './testimonials';
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import copy from 'copy-to-clipboard';
import api from '../api'
import { useAlert } from 'react-alert'
import axios from 'axios'
import userAction from "../redux/users/action";

export const Home = () => {
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUser } = userAction;
  const [loader, setLoader] = useState(false);

  const [walletInput, setWalletInput]=useState("")
  const [show, setShow]=useState(false)
  const [copyText, setCopyText]=useState("Copy to clipboard");
    
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showMonthly, setShowMonthly]=useState(false)
  const handleCloseMonthly = () => {
    setShowMonthly(false);
    handleShowBroadcast();
  };
  const handleShowMonthly = () => setShowMonthly(true);

  const [showAddWallet, setShowAddWallet]=useState(false)
  const handleCloseAddWallet = () => setShowAddWallet(false);
  const handleShowAddWallet = () => setShowAddWallet(true);

  const [showBroadcast, setShowBroadcast]=useState(false)
  const handleCloseBroadcast = () => setShowBroadcast(false);
  const handleShowBroadcast = () => setShowBroadcast(true);

  const [showVideo, setShowVideo]=useState(false)
  const handleCloseVideo = () => {
    setShowVideo(false)
    // {!userStore?.users?.user?.name &&
    //   handleShowWallet(true)
    // }
    handleShowBroadcast()
  };
  const handleShowVideo = () => setShowVideo(true);
  
  const [showWallet, setShowWallet]=useState(false)
  const handleCloseWallet = () => setShowWallet(false);
  const handleShowWallet = () => setShowWallet(true);

  useEffect(() => {
    // {!userStore?.users?.user?.name &&
    //   handleShowVideo()
    // }
    // {(userStore?.users?.user?.name && !userStore?.users?.user?.wallet) &&
    //   handleShowAddWallet()
    // }
  }, [])
  

  const handleCopy = (text) => {
    copy(text)
    setCopyText("Copied")
    setTimeout(() => {
      setCopyText("Copy to clipboard")
    }, 3000);
  }

  const addWallet = async() => {
    try {
      
      if(!walletInput) {
        alert.show("Please add TRC wallet address")
        return
      }
      setLoader(true)
      // let balance = await checkBalance(walletInput)
      // console.log(balance);
      
      // if(balance < 100) {
      //   alert.show("Your do not have enough balance to get VIP access")
      //   setLoader(false)
      //   return
      // }
  
      let payload = {
        _id:userStore?.users?.user?._id,
        wallet:walletInput,
      }
      
      const signalResponse = await api.userEdit(payload);
      // console.log(signalResponse);
      if(signalResponse?.success){
        let updatedData = userStore?.users
        updatedData.user.wallet = payload.wallet
        dispatch(setUser(updatedData));
        handleCloseAddWallet()
        alert.show("Wallet Saved")
        navigate("/post")
      }else{
        alert.show(signalResponse?.msg)
      }
      setLoader(false)
    } catch (error) {
      console.log(error.message);
      
      alert.show(error.message)
      setLoader(false)
    }

  }
  
  // const checkBalance = async(address)=> {
  //   let web33 = new Web3(process.env.REACT_APP_NODE_URL_RPC);
  //   var Trc20contract = new web33.eth.Contract(Trc20Abi, TrcContract );

  //   var ubalance1 = await Trc20contract.methods.balanceOf(address).call()
  //   return (parseInt(ubalance1) / 10**18)  
  // }


  return (
    <>
              {/* banner sec */}
        <div id="enrollment" className="banner-area banner-area-ptb">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <span data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true" className="aos-init aos-animate">FOR A BETTER FUTURE</span>
                  <h1 data-aos="fade-down" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true" className="aos-init aos-animate">First <span style={{display: 'contents'}}>Learn</span> Then <span style={{display: 'contents'}}>Earn</span></h1>
                  <p className="offer-text">
                    <span style={{color: 'red'}}>DISCLAIMER:</span>
                     We are an Educational Platform/Teachers, We dont ask for investments and we are not financial advisors. The Following groups are the only offical groups of Waqar Zaka.</p>
                  <p data-aos="fade-up" data-aos-delay={900} data-aos-duration={1000} data-aos-once="true" className="aos-init aos-animate">Experience the Legacy of Success: We are a trusted institution with a rich history of accomplishments. Our platform offers the perfect blend of knowledge and opportunity, empowering you to unlock your potential to earning money like never before. Join us now and be part of our countless success stories!</p><p className="offer-text"> Monthly Payment: <span style={{fontWeight: 'bolder', fontSize: 'large'}}>$9 </span><span className="price-cut" style={{color: 'red'}}>$50</span> (Limited Offer) </p>
                  <div className="btn-groups-container">
                    <div className="btn-groups-row">
                      <div className="btn-groups-col">
                        {/* <a href="https://web.facebook.com/100050470930692/support/?surface=page_top_cta_button&entrypoint_surface=page_top_cta_button" target='_blank'>
                            </a> */}
                          {/* <a href="https://linktr.ee/waqarzaka" target='_blank'>
                            <button className="fb-button">
                              <i className="fa-brands fa-facebook-f" />Monthly Group
                            </button>
                          </a> */}
                          <button className="fb-button" onClick={()=>handleShowMonthly()}>
                            <i className="fa-brands fa-facebook-f" />Monthly Group
                          </button>
                      </div>
                      <div className="btn-groups-col">
                        <a>
                          <button className="fb-button" onClick={handleShow}>
                            <i className="fa-brands fa-facebook-f" />Annual Group</button>
                        </a>
                      </div>
                      <div className="btn-groups-col">
                        <a href="https://whatsapp.com/channel/0029Va8bbcy7YSd6YwMxUT3f" target='_blank'>
                            <button className="wa-button">
                              <i className="fa-brands fa-whatsapp" />
                              WhatsApp Group
                            </button>
                        </a>
                      </div>
                    </div>
                  <div className="btn-groups-row">
                    <div className="btn-groups-col">
                      <a href="https://t.me/waqarzaka">
                        <button className="telegram-button">Join Telegram</button>
                      </a>
                    </div>
                  <div className="btn-groups-col">
                    <a href="https://discord.com/invite/waqarzaka">
                      <button className="discord-button">
                        Join Discord</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box">
              <a href="https://www.youtube.com/c/waqarzaka" target="_blank" className="default-btn">Youtube Channel</a>
              <ul>
                <li>
                  <a href="https://m.me/j/Abat_s17duf8Dm-5/" target="_blank" className="facebook">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/zakawaqar" target="_blank" className="twitter">
                    <i className="fa-brands fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://ig.me/j/AbbEEsvUoFTOHaPf/" target="_blank" className="instagram">
                    <i className="fa-brands fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
              </div>
              <div className="col-lg-6">
                <img className='img-fluid' src={trading} />
              </div>
            </div>
          </div>
        </div>
      {/* banner sec */}
      {/* second section */}
      <div className="featured-area featured-area-mt pb-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-6">
              <div className="featured-item">
                <i className="flaticon-studying" />
                <h3>Instructors</h3>
                <p>Benefit from 7+ years of experience and the wisdom of our seasoned instructors who are dedicated to guiding and educating you.</p>
              </div>
            </div>
            <div className="col-lg-4 col-6">
              <div className="featured-item">
                <i className="flaticon-platform" />
                <h3>Available 24/7</h3>
                <p>Support line is here to assist you at any hour, ensuring a seamless and satisfying experience with us. </p>
              </div>
            </div>
            <div className="col-lg-4 col-6">
              <div className="featured-item">
                <i className="flaticon-effective" />
                <h3>Earning</h3>
                <p>We teach you how to earn money from home in different categories, making it easy for you to understand and implement.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* second section */}
      {/* third section */}
        <div _ngcontent-bnq-c29 id="services-courses" className="courses-area pt-100 pb-70">
          <div className="container">
            <div className="section-title mb-45">
              <h2>Explore our education <b>services</b> in </h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="courses-item">
                  <img src={categoriesimg1} alt="Courses" />
                  <div className="content">
                    <h3 className="tag-btn">Block Chain</h3>
                    <h5>Learn Blockchain and Revolutionize Possibilities Across Industries!</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="courses-item">
                  <img src={categoriesimg2} alt="Courses" />
                  <div className="content">
                    <h3 className="tag-btn">Spot Trading</h3>
                    <h5>Master the Art of Spot Trading and Ride the Currents of Market Opportunities!</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="courses-item">
                  <img src={categoriesimg3} alt="Courses" />
                  <div className="content">
                    <h3 className="tag-btn">Future Trading</h3>
                    <h5>Dive into the World of Future Trading and Unlock Tomorrow's Profits Today</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="courses-item">
                  <img src={categoriesimg4} alt="Courses" />
                  <div className="content">
                    <h3 className="tag-btn">Gold Trading</h3>
                    <h5>Learn Gold Trading and Turn Market Fluctuations into Shining Opportunities!</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="courses-item">
                  <img src={categoriesimg5} alt="Courses" />
                  <div className="content">
                    <h3 className="tag-btn">Amazon</h3>
                    <h5>Learn Amazon From A to Z and Discover Limitless Opportunities! </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="courses-item">
                  <img src={categoriesimg6} alt="Courses" />
                  <div className="content">
                    <h3 className="tag-btn">Meta Verse</h3>
                    <h5>Step into the Metaverse and Shape Your Own Infinite Digital Universe!</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* third section */}
      {/* forth section */}
      <div id="about" className="enrolled-area pt-100 pb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div data-speed="0.05" data-revert="true" className="enrolled-img-two mb-30">
                <img src={enrolledimg2} alt="Enrolled" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="enrolled-content mb-30">
                <div className="section-title">
                  <h2>Waqar Zaka the most qualified and experienced instructors in this era.</h2>
                  <p> Waqar Zaka has been nominated by Binance for the Bananas Influencer Awards 2021. A total of five influencers from around the world were nominated for the award by Binance. He also received the Social Media Icon of the Year 2021 Award in the UAE. </p>
                </div>
                <a href="#enrollment" className="default-btn">Enrolled today</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* forth section */}

      {/* education */}
      <div className="container">
        <section className="sec5" data-aos="fade-right" data-aos-duration="1500">
          <h1>Our Education Videos</h1>

          <EducationalVideos />
        </section>
      </div>
      {/* education */}
      
      {/* testimonail */}
      <section className="sec5 bg-gray ptb-100" id='testimonial' data-aos="fade-right" data-aos-duration="1500">
        <div className="container">
          <h1>What people say about us</h1>

          <Testimonials />
        </div>
      </section>
      {/* testimonail */}

      <Modal show={show} onHide={handleClose} centered className='videoM'>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleClose}>
          <i className="fa-solid fa-xmark" />
          </div>

          {/* <h2 className="title color-dark mb-0">To Join Yearly Group.</h2> */}
          <h5 className="color-dark text-center mb-4">Now you can pay in crypto.</h5>

          <div className="homeVideoM mb-3">
              <iframe className='w-100' width="560" height="315" src="https://www.youtube.com/embed/6lRXNmSGT1A?si=p7CBt6WFKnZAlytw&autoplay=1&mute=1" 
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen>
              </iframe>
              {/* <video  className='w-100' controls>
                <source src={homeVideo} type="video/webm" />
                <source src={homeVideo} type="video/mp4" />
                Sorry, your browser doesn't support videos.
            </video> */}
          </div>

          <p className='teather'>TETHER- USDT</p>
          <p className='teather'>NETWORK- TRC20 ADDRESS:</p>
          <p className='teather'>TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz 
            <span className='copyToClip' onClick={()=>handleCopy("TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz")}>
              <i className="fa-regular fa-copy ms-2 cursor-pointer"></i>
              <span>{copyText}</span>
            </span> 
          </p>
          <p className='teather mb-3'>PAYMENT: <span >999$</span></p>
          
          <div className="noteTitle">Note:</div>
          {/* <div className="closed">Membership is closed! </div>
          <div className="closed">Visit again </div> */}
          <div className="note">
            Do not send any amount except yearly amount; If you send so, the amount will not be refunded and this account is just for yearly $999 worth of usdt and again it is NOT Refundable. Send your CNIC front and back picture for KYC. If you are underage send your parents or school/college id card.
          </div>
          <div className="desc1">Once you done payment Please Send the TX id and do screen record while doing transaction then Whatsapp Message on
          <a href="https://wa.me/+923082507105">+923082507105 </a> send all details on WhatsApp no call will be entertained Office working hours</div>
          <div className="desc1">01:00 PM to 09:00 PM</div>
          <div className="desc1">Monday to Friday Only</div>
         
        </div>
      </Modal.Body>
    </Modal>

    <Modal show={showMonthly} onHide={handleCloseMonthly} centered>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleCloseMonthly}>
          <i className="fa-solid fa-xmark" />
          </div>

          {/* <h2 className="title color-dark mb-0">Follow these steps.</h2>
          <div className="imgDiv">
            <img className='img-fluid' src={monthlyGroup} alt="" />
          </div> */}
          <h2 className="title color-dark mb-0">Join Monthly Group</h2>
          <div className="homeVideoM py-4">
            {/* <video  className='w-100' controls controlsList="nodownload" >
              <source src={monthlyVideo} type="video/webm" />
              <source src={monthlyVideo} type="video/mp4" />
              Sorry, your browser doesn't support videos.
            </video> */}
            <iframe className='w-100' width="560" height="315" src="https://www.youtube.com/embed/4t_CkcCq4Iw?si=pzvqZsSv3TtK8U77&autoplay=1&mute=1" 
          title="YouTube video player" frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerpolicy="strict-origin-when-cross-origin" 
          allowfullscreen>
          </iframe>
          </div>
          <div className='text-center'>
            <a className='default-btn' href="https://linktr.ee/waqarzaka" target="_blank" rel="noopener noreferrer">Joining Link</a>
          </div>
          
         
        </div>
      </Modal.Body>
    </Modal>

      <Modal show={showVideo} onHide={handleCloseVideo} centered className='videoM'>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleCloseVideo}>
          <i className="fa-solid fa-xmark" />
          </div>
          <div className="homeVideoM py-4">
          {/* <iframe className='w-100' width="560" height="315" src="https://www.youtube.com/embed/4t_CkcCq4Iw?si=pzvqZsSv3TtK8U77&autoplay=1&mute=1" 
          title="YouTube video player" frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerpolicy="strict-origin-when-cross-origin" 
          allowfullscreen>
          </iframe> */}
          <h5 className="color-dark text-center mb-4">Learn to Trade Like a Pro: No Boss, No Product—Just Profits from Crypto, Futures! See How Waqar Zaka Does It!</h5>
          {/* <div className="desc1 text-center mb-3">Learn expert strategies, enhance your trading skills, and dominate the crypto market. Whether you're a beginner or a pro, our team will help you succeed in leverage trading. </div> */}
          <div className='text-center d-flex jc-center'>
            <NavLink className="nav-link me-0" to="/signup"> 
                <button className='default-btn'>Register</button>
            </NavLink>
            <NavLink className="nav-link" to="/login"> 
                <button className='default-btn'>Login</button>
            </NavLink>
          </div>
          <h5 className="color-dark text-center mt-4">Crypto, Futures aur Stocks Seekhein: Na Boss, Na Product—Sirf Ghar Baithe Kamaai! Dekhein Waqar Zaka Kahan Invest aur Trade Kar Rahe Hain!</h5>
          </div>
         
        </div>
      </Modal.Body>
    </Modal>

      <Modal show={showWallet} onHide={handleCloseWallet} centered className='videoM'>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleCloseWallet}>
          <i className="fa-solid fa-xmark" />
          </div>
          <div className="d-flex jc-center pt-5 walletBtn">
            <NavLink className="default-btn me-2" to="/signup"> 
                Register
            </NavLink>
            <NavLink className="default-btn" to="/login"> 
                Login
            </NavLink>
          </div>
         
        </div>
      </Modal.Body>
    </Modal>

    <Modal show={showAddWallet} onHide={handleCloseAddWallet} centered>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleCloseAddWallet}>
          <i className="fa-solid fa-xmark" />
          </div>

          <h3 className="title color-dark mb-0">For VIP Access</h3>
          <p className="teather mb-0 text-center">Please add TRC Wallet Address</p>
          <p className='mt-2'>Please note: Access to VIP services requires a minimum wallet balance of 100 USDT. If your balance is below this amount, you will not be able to access VIP services.</p>
          <div className="form-group my-4">
            <input type="text" onChange={(e)=>setWalletInput(e.target.value)} className="form-control" placeholder='Address...' />
          </div>
          <div className="text-center">
            <button className='default-btn loaderBtn' disabled={loader? true:false} onClick={addWallet} >
              {loader && 
              <i className="fa fa-spinner fa-pulse mx-2"></i> 
              }
              Add Wallet
            </button>
          </div>
         
        </div>
      </Modal.Body>
    </Modal>

    <Modal  size="lg" show={showBroadcast} onHide={handleCloseBroadcast} centered>
      <Modal.Body>
        <div className="otpModalMain broadcastModal">
          <div className="closeIcon" onClick={handleCloseBroadcast}>
          <i className="fa-solid fa-xmark" />
          </div>
          
          <div className="broadCastIco"><img src={broadCastIco} alt="" /></div>
          <h3 className="broadTitle mb-0">Join our broadcast channels for exclusive crypto and stock insights, and track Waqar Zaka's investments.</h3>
          <div className="broadBtnMain">
            <a className='themeBtn' href="https://www.instagram.com/channel/AbbEEsvUoFTOHaPf/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-instagram"></i>
              Join Insta Broadcast
            </a>
            <a className='themeBtn' href="https://m.me/j/Abat_s17duf8Dm-5/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-facebook"></i>
              Join FB Broadcast
            </a>
          </div> 
         
        </div>
      </Modal.Body>
    </Modal>
    </>
  )
}
