import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import api from "../api/index"
import { useAlert } from "react-alert";
import OtpInput from "react-otp-input";
import Modal from "react-bootstrap/Modal";
import { OtpModal } from './otpModal';
import CountrySelect from './countrySelect';
import { TextField } from '@mui/material';
import axios from 'axios';
import showPassImg from "../images/showPass.svg"
import hidePass from "../images/hidePass.svg"
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { useEffect } from 'react';
import WAValidator from 'multicoin-address-validator';

export const Signup = () => {
  const alert = useAlert();
  const ref = useRef();

  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [validAddress, setValidAddress] = useState(false);

  const [showModal, setShowModal]= useState(false);
  const [phoneNum, setPhoneNum]= useState("");
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loader, setLoader] = useState(false);

  const register = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        setLoader(false); 
        return alert.show(validatorsResponse);
      }

      const payload = {
        email: data.email,
        name: data.name,
        password: data.password,
        // wallet: data.wallet,
        country: data.country,
        phone: phoneNum,
        dob: data.dob,
        gender: data.gender,
      };
      console.log(payload);
      const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/register`;

      axios.post(url, payload)
      .then(data=> {      
        // console.log(data);
          
        setShowModal(true)
        alert.show("Registered successfully, Please verify email");
        setLoader(false); 
      })
      .catch(error => {
        console.log(error);
        setLoader(false); 
        alert.show(error.response.data)
      })

      // const registerationResponse = await api.register(payload);
      // console.log(registerationResponse);

      // setData({
      //   email: "",
      //   password: "",
      //   confirmPassword: "",
      // })
      // navigate("/login");
    } catch (e) {
      console.log(e.message);
      alert.show("Something went wrong");
      setLoader(false);
    }
  };

  function hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  const validators = () => {
    let regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)*$/;
    // if (!data.firstName) {
    //   return "First name is required";
    // }
    if (!data.name) {
      return "User name is required";
    }
    // if (hasWhiteSpace(data.name)) {
    //   return "Space are not allowed in username";
    // }
    if (!data.email) {
      return "Email is required";
    }
    // if (!data.phone) {
    //   return "Phone is required";
    // }
    // if (isNaN(data.phone)) {
    //   return "Phone is not correctly formatted";
    // }
    if (data.email && !regexEmail.test(data.email)) {
      return "Email is not correctly formatted";
    }
    // if (!data.wallet) {
    //   return "Wallet is required";
    // }
    // if (!validAddress) {
    //   return "Wallet address is invalid";
    // }
    if (!phoneNum) {
      return "Phone is required";
    }
    if (phoneNum.length > 15 ) {
      return "Phone must be less than 15 number";
    }
    if (!data.country) {
      return "Country is required";
    }
    if (!data.gender) {
      return "Gender is required";
    }
    if (!data.dob) {
      return "Date of birth is required";
    }
    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password.length < 8) {
      return "Password must be 8 characters long";
    }
    if (!data.confirmPassword) {
      return "Confirm password is required";
    }
    if (data.password !== data.confirmPassword) {
      return "Password and Confirm password not matched";
    }

    return false;
  };

  const onTextChange = (e,type) => {
    console.log(e);
    
    if(e.label){
      setData({ ...data, country: e.label });
    }else{
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  // useEffect(() => {
  // if(data?.wallet) {
  //   let firstLetter = data?.wallet.slice(1);
  //   console.log(firstLetter);
  //   var valid = WAValidator.validate(data?.wallet, 'TRX');
  //     if(valid) {
  //       console.log('This is a valid address');
  //       setValidAddress(true)
  //     }
  //     else {
  //       console.log('Address INVALID');
  //       setValidAddress(false)
  //     }
  // }
  // }, [data?.wallet])
  

  return (
    <>
    <div className="contact-widget-area pb-70">
        <div className="container">
            <div className="section-title text-center mb-45">
                <span />
                <h2 className='text-uppercase'>Signup</h2>
            </div>
            <div className="contact-form signup-form">
                <form noValidate id="contactForm" className="ng-invalid ng-dirty ng-touched">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                {/* <TextField className='form-control p-0' id="outlined-basic" label="Name" name="name" onChange={(e)=>onTextChange(e)} /> */}
                                <input onChange={(e)=>onTextChange(e)} type="text" name="name" id="name" required  placeholder="Name" className="form-control ng-dirty ng-invalid ng-touched" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <input onChange={(e)=>onTextChange(e)} type="text" name="email" id="email" required  placeholder="Email" className="form-control ng-dirty ng-invalid ng-touched" />
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <div className="form-group walletInput">
                                {data?.wallet &&
                                <>
                                  {validAddress ?
                                  <span className="isValid"> 
                                    <i className="fa-solid fa-check valid"></i> 
                                    Address is valid
                                  </span>
                                  :
                                  <span className="isValid">  
                                    <i className="fa-solid fa-xmark invalid"></i>                                    
                                    Invalid Address
                                  </span>
                                  }
                                </>
                                }
                              <input type="text" onChange={(e)=>onTextChange(e)} name='wallet' required className="form-control" placeholder='Please add TRC Wallet Address' />
                              <span className='note'>Please note: Access to VIP services requires a minimum wallet balance of 100 USDT.</span>
                            </div>
                        </div> */}
                        <div className="col-lg-6">
                            <div className="form-group">
                                <CountrySelect onTextChange={onTextChange} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group phone">
                              <PhoneInput
                                  className="form-control"
                                  international
                                  countryCallingCodeEditable={false}
                                  value={phoneNum}
                                  onChange={setPhoneNum}
                                  placeholder="Enter Whatsapp Number"
                              />                            
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                              <i className="fa-solid fa-caret-down  arrowDownIco" />
                                <select onChange={(e)=>onTextChange(e)} name="gender" id="" className='form-control ng-dirty ' >
                                  <option value="" >Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group position-relative">
                              {ref?.current?.value?"": <div className="dobPlaceholder">Date of Birth</div>}
                                <input 
                                  ref={ref}
                                  // onFocus={() => (ref.current.type = "date")}
                                  // onBlur={() => (ref.current.type = "date")}
                                  onChange={(e)=>onTextChange(e)} 
                                  type="date" 
                                  name="dob" id="dob" required  placeholder="Date of Birth" className="form-control ng-dirty ng-invalid ng-touched"
                                 />
                            </div>
                        </div>
                       
                        {/* <div className="col-lg-6">
                            <div className="form-group">
                                <input onChange={(e)=>onTextChange(e)} type="text" name="phone" id="phone" required  placeholder="Phone" className="form-control ng-dirty ng-invalid ng-touched" />
                            </div>
                        </div> */}
                        <div className="col-lg-6">
                            <div className="form-group showPassInput">
                                <input onChange={(e)=>onTextChange(e)} type={showPass?"text":"password"} name="password" id="password" required  placeholder="Password" className="form-control ng-dirty ng-invalid ng-touched" />
                                {showPass?
                                <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                                :
                                <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group showPassInput">
                                <input onChange={(e)=>onTextChange(e)} type={showPass?"text":"password"} name="confirmPassword" id="password" required  placeholder="Confirm Password" className="form-control ng-dirty ng-invalid ng-touched" />
                                {showPass?
                                <img onClick={()=>setShowPass(false)} src={showPassImg} alt="" />
                                :
                                <img onClick={()=>setShowPass(true)} src={hidePass} alt="" />
                                }
                            </div>
                        </div>
                        <div className="col-12 my-3">
                            <div>
                                <Link className="createAccount" to={"/login"}>Already have an account?</Link>
                            </div>
                        </div>
             
                        <div className="col-lg-12 col-md-12 text-center">
                          
                            <button onClick={register} type="submit" className="default-btn">
                              {loader && 
                              <i className="fa fa-spinner fa-pulse mx-2 mb-1" style={{position:"relative",top:"-0px"}}></i> 
                              }
                               Submit 
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <OtpModal show={showModal} setShow={setShowModal} email={data.email} />

    </>
  )
}


