import React,{useEffect} from "react";
import { BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";
import ReactDOM from 'react-dom';


import { NavLink,Link } from "react-router-dom";
import $ from 'jquery';

// import profile from "../../../images/profile.jpg";
import logo from "../../../images/logoLight.png";
import dashboardIco from "../../../images/dashboardIco.svg";


function SidebarPortfolio() {

    return(
        <>

             {/* Sidebar */}
          <ul className="navbar-nav shadow sidebar sidebar-dark accordion zi-1 pb-100" id="accordionSidebar">
            {/* Sidebar - Brand */}
            <Link className="sidebar-brand d-flex align-items-center justify-content-center mt-2" to="/">
              <div className="sidebar-brand-text mx-3">
                <img src={logo} className="w-100" alt="apollo" />
              </div>
              <div className="mini mx-3">
                <img src={logo} className="w-100" alt="apollo" />
              </div>
            </Link>
           
            {/* sidebar links */}
            <li className="nav-item my-1 mt-5">
                <NavLink className="nav-link sidebar-Link fw-500" to="/portfolio/dashboard/1">
                    <img className="" src={dashboardIco} alt="" />
                    <span className="">Overview</span>
                </NavLink>
            </li>
            <li className="nav-item my-1 ">
                <NavLink className="nav-link sidebar-Link fw-500" to="/portfolio/tx">
                    <img className="" src={dashboardIco} alt="" />
                    <span className="">Create Porfolio</span>
                </NavLink>
            </li>
            {/* <li className="nav-item my-1">
              <NavLink className="nav-link sidebar-Link fw-500" to="/admin/users">
                <span className="">Users</span></NavLink>
            </li> */}
     
           
           
          
            {/* Nav Item - Pages Collapse Menu */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-cog" />
                <span>Components</span>
              </a>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Custom Components:</h6>
                  <a className="collapse-item" href="buttons.html">Buttons</a>
                  <a className="collapse-item" href="cards.html">Cards</a>
                </div>
              </div>
            </li> */}
           
       
          </ul>
          {/* End of Sidebar */}

        </>
    )
    
}
export default SidebarPortfolio