import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";

import './App.css';
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/flaticons.css'
import './component/global/global.css'
import { navbarAnim } from './assets/js/custom';

import AOS from "aos";
import "aos/dist/aos.css";
import { Navbar } from './component/navbar';
import { Footer } from './component/footer';
import { Home } from './component/home';
import { Survey } from './component/survey';
import { Signup } from './component/signup';
import { Login } from './component/login';
import api from './api';
import AuthNotLoginRoutes from './helpers';
import userAction from "./redux/users/action";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './component/global/sidebar/sidebar';
import { NavbarAdmin } from './component/global/navbar/navbar';
import Users from './component/global/users';
import { AdminLogin } from './component/global/adminlogin';
import AllSurvey from './component/global/survey';
import PromotionMail from './component/global/promotionMail';
import AddPost from './component/global/addPost';
import AllAdminPost from './component/global/allPostAdmin';
import { UserPost } from './component/userPost';
import axios from 'axios';
import { DataInfo } from './component/global/dataInfo';
import { ForgetPass } from './component/forgetPass';
import { ResetPass } from './component/resetPass';
import { Portfolio } from './component/portfolio/portfolio';
import { ConnectPage } from './component/portfolio/connectPage';
import { PortfolioDashboard } from './component/portfolio/dashboard/dashboard';
import TupLokersMail from './component/global/lockersMail';
import LockersList from './component/global/lockersList';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { config } from './helpers/wagmi'
const queryClient = new QueryClient()

function App() {
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const adminStore = useSelector((state) => state.adminReducer);
  const location = useLocation()
  useEffect(() => {
   
  navbarAnim()
  AOS.init();
  AOS.refresh();
  checkUserSession()
  }, [])

  const checkUserSession = async() => {
    let token=localStorage.getItem("token")
    // console.log(token);
    if(token){
      let payload = {token:token}
      const registerationResponse = await api.verifySession(payload);
      // console.log(registerationResponse);
      if(registerationResponse?.token){
        axios.defaults.headers.common["Authorization"]=registerationResponse?.token
      }else{
        dispatch(setUser({}));
        localStorage.removeItem('token')
      }
    }
  }
  
  return (
    <div className="App">      
        {adminStore?.admin?.admin?.email ?
          <div id="wrapper">
              <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    <NavbarAdmin />
                    <Routes>
                      <Route exact={true} path="/admin/datainfo" element={<DataInfo />} />
                      <Route exact={true} path="/admin/users" element={<Users />} />
                      {/* <Route exact={true} path="/admin/survey" element={<AllSurvey />} /> */}
                      <Route exact={true} path="/admin/sendmail" element={<PromotionMail />} />
                      <Route exact={true} path="/admin/sendmail-tup-locker" element={<TupLokersMail />} />
                      <Route exact={true} path="/admin/tup-locked" element={<LockersList />} />
                      <Route exact={true} path="/admin/addpost" element={<AddPost />} />
                      <Route exact={true} path="/admin/post" element={<AllAdminPost />} />
                    </Routes>
                    
                </div>
            </div>
          </div>
        : (location.pathname == "/portfolio/connect" || 
          location.pathname.includes('/portfolio/dashboard') || 
          location.pathname.includes('/portfolio/tx')) 
          ? 
        <>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}> 
              <Routes>
                <Route exact={true} path="/portfolio/connect" element={<ConnectPage />} />
                <Route exact={true} path="/portfolio/*" element={<PortfolioDashboard  />} />
              </Routes>
            </QueryClientProvider> 
          </WagmiProvider>
        </>
        :
        <>
        <Navbar />
        <Routes>
          <Route exact={true} path="/" element={<Home  />} />
          {/* <Route exact={true} path="/survey" element={<Survey />} /> */}
          <Route exact={true} path="/post" element={<UserPost />} />
          <Route element={<AuthNotLoginRoutes />}>
            <Route exact={true} path="/signup" element={<Signup />} />
          </Route>
          <Route element={<AuthNotLoginRoutes />}>
            <Route exact={true} path="/login" element={<Login />} />
          </Route>
          <Route element={<AuthNotLoginRoutes />}>
            <Route exact={true} path="/forget-password" element={<ForgetPass />} />
          </Route>
          <Route element={<AuthNotLoginRoutes />}>
            <Route exact={true} path="/reset-password/:id" element={<ResetPass />} />
          </Route>
          <Route exact={true} path="/admin-login" element={<AdminLogin />} />
          <Route exact={true} path="/portfolio" element={<Portfolio />} />
        </Routes>
        <Footer />
        </>
        }




    </div>
  );
}

export default App;
