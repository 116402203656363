import React, { useEffect, useMemo, useState } from 'react'
import SelectNew from "react-select";
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import api from '../../../api';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { BsEye, BsThreeDotsVertical, BsWallet } from "react-icons/bs";
import { AtmOutlined, WalletSharp } from '@mui/icons-material';
import { Loader } from '../../global/loader';

export const TxPortfolio = () => {
    const navigate = useNavigate()
    const userStore = useSelector((state) => state.userReducer);
    const alert = useAlert();

    const [rates, setRates] = useState([]);
    const [tx, setTx] = useState(null);
    const [currencies, setCurrencies] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [date, setDate] = useState(new Date().toISOString().slice(0, 16))
    const [showBalance, setShowBalance] = useState(true);
    const [Best_Performer, setBest_Performer] = useState(null);
    const [Worst_Performer, setWorst_Performer] = useState(null);
    const [loader, setLoader] = useState(false);

    async function GetPortfolio() {
        try {
            setLoader(true)
            // console.log(userStore?.users?.user?._id);
            const res = await api.getPortfolio(userStore?.users?.user?._id);
            // console.log(res);
            
            if (res?.success) {
                // console.log("res.message");
                // console.log(res.message);
                // console.log(res.formattedData);

                if ((res.result).length > 0) {
                    // console.log(res.result);
                    const data = (res.result).filter((e) => {
                        return (e.totalQuantity != 0)
                    })
                    
                    setLoader(false)
                    // console.log("data 6876 786 876 87 6 786 87,,,,,,,");
                    // console.log(data);
                    setTx(data)

                } else {
                    setLoader(false)
                    setTx(null)

                }

            } else {
                setTx(null)
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
            setLoader(false)
        }

    }

    
    const GetLatestRates = async () => {
        try {
            setLoader(true)
            const res = await api.getLatestQuotes();
            // console.log("res");
            // console.log(res);
            
            if (res?.success) {
                // console.log("res.message", res.message.data)
                // console.log(res.coins)
                setRates(res.message.data)
                setCurrencies(res.coins)

            } else {
                setRates([])
                setCurrencies([])
            }

            setLoader(false)

        } catch (error) {
            setLoader(false)
            console.log(error.message);
        }
    }

    function findCurrency(name) {
        if (rates.length > 0) {
            return rates.find((e) => {
                return (e?.slug)?.toLowerCase() == name?.toLowerCase() || (e?.name)?.toLowerCase() == name?.toLowerCase() || null
            })
        }
    }
    async function deleteThisCoin(coin) {
        const res = await api.DeleteThisCoin(coin, userStore?.users?.user?._id)
        if (res.success) {
            alert.show("Deleted successfully")
            window.location.reload()
        }
    }

    const handleImageError = (event) => {
        event.target.src = `${process.env.PUBLIC_URL1}/icon-not-found.png`;
        event.target.onerror = null;
        event.target.alt = "Not Found";
    };

    function calculateTotalPortfolio() {
        if (tx != null) {
            // Map transactions to USD values based on rates
            var txWithUSDValues = tx.map((transaction) => {
                var matchingRate = rates.find((rate) => rate.name === transaction._id || rate.slug === transaction._id);
                var usdPrice = matchingRate ? matchingRate.quote.USD.price : 0;
                var totalValueUSD = transaction.totalQuantity * usdPrice;
                return { ...transaction, totalValueUSD };
            });

            // Calculate total USD value
            var totalUSDValue = txWithUSDValues.reduce((accumulator, transaction) => accumulator + transaction.totalValueUSD, 0);
            return totalUSDValue;
        }
    }

    function calculateTotalPrice() {
        if (tx != null) {
            var AllPL = tx.map((transaction) => {
                var matchingRate = rates.find((rate) => rate.name === transaction._id || rate.slug === transaction._id);
                var usdPrice = matchingRate ? matchingRate.quote.USD.price : 0;

                // console.log("transaction.totalValue", transaction);
                // console.log("usdPrice", usdPrice);

                var PL = (usdPrice * transaction.totalQuantity) - transaction.totalValue;
                return PL;
            });


            var AllTimePL = AllPL.reduce((accumulator, value) => accumulator + value, 0);
            // console.log("AllTimePL, AllPL");
            // console.log(AllTimePL, AllPL);
            
            return { AllTimePL, AllPL };
        }
    }

    // calculate best and worst performance
    function decider() {
        setLoader(true)
        // console.log("decider -> tx", tx);
        const data = tx.map((transaction) => {
            var matchingRate = rates.find((rate) => rate.name === transaction._id || rate.slug === transaction._id);
            var usdPrice = matchingRate ? matchingRate.quote.USD.price : 0;
            var totalValueUSD = transaction.totalQuantity * usdPrice;
            var pl = (usdPrice * transaction.totalQuantity) - transaction.totalValue;
            return { ...transaction, totalValueUSD, pl };
        });

        if (data.length === 0) {
            setTx(null)
            setBest_Performer(null)
            setWorst_Performer(null)
            setLoader(false)

            return { min: null, max: null };
        }

        // console.log("deciderd", data);


        if (data.length == 1) {
            // console.log("12312331231312312312", data)
            return data.reduce((result, current) => {
                // console.log("result", result);
                // console.log("current", current);
                if (result.min === null || current.pl < result.min) {
                    result.min = current.pl;
                    setWorst_Performer(current)
                    setBest_Performer(current)
                    setLoader(false)
                    return
                }

                if (result.max === null || current.pl > result.max) {
                    result.max = current.pl;
                    setBest_Performer(current)
                    setWorst_Performer(current)
                    setLoader(false)
                    return
                }
                setLoader(false)


                return result;
            }, { min: null, max: null });
        }

        return data.reduce((result, current) => {
            // console.log("result", result);
            // console.log("current", current);
            if (result.min === null || current.pl < result.min) {
                result.min = current.pl;
                setWorst_Performer(current)
            }

            if (result.max === null || current.pl > result.max) {
                result.max = current.pl;
                setBest_Performer(current)
            }

            setLoader(false)
            return result;
        }, { min: null, max: null });

    }


    useEffect(() => {        
        if(userStore?.users?.user?._id){
            GetPortfolio()
            GetLatestRates()
        }
        
      }, [userStore?.users])

      useMemo(() => {
        if (rates.length > 0 && tx != null) decider()

    }, [tx, rates])

  return (
    <div className='portfolioPage'>
         <div className="selectChain">
             <AddTx rates={rates} currencies={currencies} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} date={date} setDate={setDate} GetPortfolio={GetPortfolio} Get_Latest_Rates={GetLatestRates} />

        </div>
                <div className="alltimeRow">
                    <section>
                        <div className="head">
                            <div className="left">
                                <div className='info'>
                                    <WalletSharp style={{ fontSize: "30px" }} /> <span>My Portfolio</span>
                                </div>
                                <div className="TotalPortfolioAmount">
                                    {
                                        calculateTotalPortfolio() > 0 && <h2 className='mb-0'>{showBalance ? (calculateTotalPortfolio())?.toFixed(2) : "xxxxx"} <BsEye onClick={() => setShowBalance(!showBalance)} style={{ fontSize: "20px", cursor: "pointer" }} /> </h2>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="portfolio-area row">
                          
                                <div className="col-md-4">

                                    <div className="card enable-shadows">
                                        <p className='atp mb-0 pb-0'>All-time profit</p>
                                        {
                                            (tx != null) ?
                                                <>
                                                    {
                                                        (calculateTotalPrice())?.AllTimePL >= 0 ?
                                                            <h2 className={"text-success out mb-0 pb-3 mb-1"}>&nbsp;+ ${(Math.abs((calculateTotalPrice())?.AllTimePL))?.toFixed(2)} </h2>
                                                            :
                                                            <h2 className={"text-danger out mb-0 pb-3 mb-1"}>&nbsp;- ${(Math.abs((calculateTotalPrice())?.AllTimePL))?.toFixed(2)} </h2>
                                                    }
                                                </>
                                                :
                                                <h2 className={"text-success out mb-0 pb-3 mb-1"}>&nbsp; $0 </h2>
                                        }
                                    </div>
                                </div>
                           
                                <div className="col-md-4">
                                    <div className="card">
                                        <p className='atp mb-0'>Best Performer</p>
                                        {
                                            Best_Performer != null ?
                                                <>
                                                    <div className='cry'>
                                                        <img src={`${process.env.PUBLIC_URL1}/icon/${(findCurrency(Best_Performer?._id))?.symbol}.svg`} alt="icon" width={"30px"} onError={(e) => handleImageError(e)} />
                                                        <h4 className='mb-0'>{Best_Performer?._id}</h4>
                                                    </div>
                                                    {
                                                        (Best_Performer?.pl) > 0 ?
                                                            <p className='text-success mb-0 in'>+ ${(Math.abs(Best_Performer?.pl)).toFixed(2)} </p>
                                                            :
                                                            <p className='text-danger mb-0 in'>- ${(Math.abs(Best_Performer?.pl)).toFixed(2)} </p>
                                                    }
                                                </>
                                                :
                                                <div className='cry'>
                                                    <h4 className='mb-0'>0</h4>
                                                </div>
                                        }
                                    </div>
                                </div>
                       
                                <div className="col-md-4">
                                    <div className="card">
                                        <p className='atp mb-0'>Worst Performer</p>
                                        {
                                            Worst_Performer != null ?
                                                <>
                                                    <div className='cry'>
                                                        <img src={`${process.env.PUBLIC_URL1}/icon/${(findCurrency(Worst_Performer?._id))?.symbol}.svg`} alt="icon" width={"30px"} onError={(e) => handleImageError(e)} />
                                                        <h4 className='mb-0'>{Worst_Performer?._id}</h4>
                                                    </div>
                                                    {
                                                        (Worst_Performer?.pl) > 0 ?
                                                            <p className='text-success mb-0 in'>+ ${(Math.abs(Worst_Performer?.pl)).toFixed(2)} </p>
                                                            :
                                                            <p className='text-danger mb-0 in'>- ${(Math.abs(Worst_Performer?.pl)).toFixed(2)} </p>
                                                    }
                                                </>
                                                :
                                                <div className='cry'>
                                                    <h4 className='mb-0'>0</h4>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                    </section>
                </div>
        <div className="portCard">
                <div className="head">Assets</div>
                <div className="myPortForm">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Current Price</th>
                                    <th scope="col">Avg. Buy Price</th>
                                    <th scope="col">Holdings</th>
                                    <th scope="col">Profit/Loss</th>
                                    <th scope="col">24h%</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                (tx != null && tx.length > 0) ?
                                    tx.map((t, i) => {
                                        return <tr key={i} className='py-3'>
                                            <td scope="row">
                                                <img src={`${process.env.PUBLIC_URL1}/icon/${((findCurrency(t?._id))?.symbol)?.toLowerCase()}.svg`} alt="icon" width={"25px"} onError={(e) => handleImageError(e)} />
                                                <span> {(findCurrency(t?._id))?.name} </span>
                                                <span> {(findCurrency(t?._id))?.symbol} </span>
                                            </td>
                                            <td>${(findCurrency(t?._id)?.quote?.USD?.price)?.toFixed(2) || 0}</td>
                                            <td>${(t.averageBuyPrice)?.toFixed(2)}</td>
                                            <td>{t.totalQuantity}</td>
                                            <td className={((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue) >= 0 ? "text-success" : "text-danger"}>
                                                ${
                                                    isNaN(((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue)?.toFixed(2)) ? 0
                                                    :
                                                ((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue)?.toFixed(2)
                                                }
                                            </td>
                                            <td
                                                className={ findCurrency(t?._id)?.quote?.USD?.percent_change_24h >= 0 ? "text-success" : "text-danger"}>
                                                    {findCurrency(t?._id)?.quote?.USD?.percent_change_24h || 0}
                                            </td>
                                            {/* <td
                                                className={((((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue) / t.averageBuyPrice) * 100) >= 0 ? "text-success" : "text-danger"}>
                                                    {isNaN(((((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue) / t.averageBuyPrice) * 100)) ?
                                                    0
                                                    :
                                                    ((((findCurrency(t?._id)?.quote?.USD?.price * t.totalQuantity) - t.totalValue) / t.averageBuyPrice) * 100).toFixed(2)
                                                    }%
                                            </td> */}
                                            <td>
                                                <div className="dropdown as23">
                                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <BsThreeDotsVertical />
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <Button className='btn btn-light dropdown-item' onClick={() => deleteThisCoin((findCurrency(t?._id))?.name)}> Remove</Button>
                                                        </li>
                                                        {/* <li>
                                                            <ViewTXs coin={(findCurrency(t?._id))?.name} />
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <>
                                        <tr className='py-3'>
                                            <td scope="row"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className='py-3'>
                                            <td scope="row"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                    }
                                </tbody>
                        </table>
                    </div>
                </div>
        </div>
        {loader && <Loader />}

    </div>
  )
}


function AddTx({ rates, currencies, selectedCurrency, setSelectedCurrency, date, setDate, GetPortfolio, Get_Latest_Rates }) {
    const alert = useAlert();
  const userStore = useSelector((state) => state.userReducer);

    const [show, setShow] = useState(false);
    const [tab, setTab] = useState("buy")
    const [quantity, setQuantity] = useState(1)
    const [price, setPrice] = useState(0.00)
    const [transferType, setTransferType] = useState("transferIn")

    const handleClose = () => {
        setShow(false);
        // handleClosePortfolio()
    }
    const handleShow = () => {
        setShow(true);
        setDate(new Date().toISOString().slice(0, 16))
    };

    const submit = async () => {

        if (selectedCurrency == null) return alert.show("Please select any coin");

        let payload = {
            userID: userStore?.users?.user?._id,
            coin: (selectedCurrency.name).toLowerCase(),
            quantity: quantity,
            datetime: date,
        }

        if (tab == "transfer") {
            payload.action = transferType
        } else {
            payload.action = tab
            payload.price_per_coin = price
        }

        // console.log("payload", payload);

        const res = await api.addPortfolio(payload)
        if (res.success) {
            handleClose()
            alert.show(res.message)
            GetPortfolio()
            Get_Latest_Rates()
        }
        else alert.show(res.message)
    }



    return (
        <>
            {/* <Button variant="success" style={{ marginRight: "10px" }} > + Add Transaction </Button> */}
            {/* <button className='btn btn-success me-2 add-tx-sm w-100' onClick={() => handleShow()}>Add TX</button> */}
            <button className="sBtn active" onClick={() => handleShow()}>Add Transaction</button>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5 className='bg-black mb-0 py-1' style={{ fontSize: "22px", fontWeight: 700 }}>Create Portfolio</h5>
                </Modal.Header>

                <Modal.Body className='px-3 portfolio-modal' close>
                    <SelectNew
                        placeholder="Select any Coin"
                        options={rates}
                        isOptionDisabled={(option) => (option.name == '' || option.name == null)}
                        value={selectedCurrency}
                        getOptionLabel={option =>
                            `${option.name}`
                        }
                        getOptionValue={option => `${option.name}`}
                        onChange={(value) => { setSelectedCurrency(value); console.log(value); setPrice((value.quote.USD.price).toFixed(2)); }}
                        loadingMessage={"Loading..."}
                        maxMenuHeight={200}
                        required
                    />

                    <div className='atx-modal-tabs'>
                        <button className={`btn ${tab == "buy" && "text-light fw-bold active"}`} onClick={() => setTab("buy")}>Buy</button>
                        <button className={`btn ${tab == "sell" && "text-light fw-bold active"}`} onClick={() => setTab("sell")}>Sell</button>
                        {/* <button className={`btn ${tab == "transfer" && "text-light fw-bold active"}`} onClick={() => setTab("transfer")}>Transafer</button> */}
                    </div>


                    {/* {
                        tab == "transfer" &&
                        <div className="col-12">
                            <label className="form-label">Transfer</label>
                            <div className="dropdown atx-modal border">
                                <button className="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {transferType == "transferIn" ? "Transfer In" : "Transfer Out"}
                                </button>
                                <ul className="dropdown-menu atx-modal">
                                    <li className='dropdown-item' onClick={() => setTransferType("transferIn")}>
                                        <p className='mb-0'> Transfer In</p>
                                    </li>
                                    <li className='dropdown-item' onClick={() => setTransferType("transferOut")}>
                                        <p className='mb-0'> Transfer Out</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    } */}

                    <div className="row g-3">
                        <div className={`${tab == "transfer" ? "col-12" : "col-md-6"}`}>
                            <label className="form-label">Quantity</label>
                            <input type="number" className="form-control" placeholder="0.00" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                        </div>

                        {
                            (tab == "buy" || tab == "sell") &&
                            <div className="col-md-6">
                                <label className="form-label">Price Per Coin</label>
                                <input type="number" className="form-control" placeholder="0.00" value={price} onChange={(e) => setPrice(e.target.value)} />
                            </div>
                        }

                        <div className="col-12">
                            <input type="datetime-local" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                        </div>

                        {
                            (tab == "buy" || tab == "sell") &&

                            <div className='col-12'>
                                <div className="a233nb">
                                    <p>Total {tab == "buy" ? "Spent" : "Received"}</p>
                                    <h3>$ {(quantity * price).toFixed(2)}</h3>
                                </div>
                            </div>
                        }

                        <div className="col-12">
                            <button type="button" onClick={() => submit()} className="btn themeBtn1 w-100 py-2">Add Transaction</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}
