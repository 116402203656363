import React, { useState } from 'react'
import logo from "../images/logo.png"
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import userAction from "../redux/users/action";
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import copy from 'copy-to-clipboard';
import broadCastIco from '../images/broadCastIco.png';

export const Navbar = () => {
  const userStore = useSelector((state) => state.userReducer);
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [courseData, setCourseData]=useState([])
  const [copyText, setCopyText]=useState("Copy to clipboard");
  
  const [show, setShow]=useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showYearly, setShowYearly]=useState(false)
  const handleCloseYearly = () => setShowYearly(false);
  const handleShowYearly = () => setShowYearly(true);

  const [showBroadcast, setShowBroadcast]=useState(false)
  const handleCloseBroadcast = () => setShowBroadcast(false);
  const handleShowBroadcast = () => setShowBroadcast(true);

  const logout = () => {
    dispatch(setUser({}))
    localStorage.clear()
    navigate("/")
  }

  const handleSetBasic = () => {    
    setCourseData(courseData1)
  }
  const handleSetAdvance = () => {
    setCourseData(courseDataAdvance)
  }

  const handleCopy = (text) => {
    copy(text)
    setCopyText("Copied")
    setTimeout(() => {
      setCopyText("Copy to clipboard")
    }, 3000);
  }
  
  useEffect(() => {
    setCourseData(courseData1)
  }, [])
  


  return (
    <div className="navbar-area ">
    <div className="container-fluid">
      <nav className="navbar navbar-expand-xl navbar-light ">
        <div className="container-fluid">
          <NavLink to={"/"} className="navbar-brand" href="#">
            <img src={logo} alt="" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/"> Home </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={handleShowBroadcast} > Broadcast Channels </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={handleShowYearly} > Join Yearly </a>
              </li>
              {userStore?.users?.user?._id &&
              <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio"> Portfolio </NavLink>
              </li>
              }
              <li className="nav-item">
                <a className="nav-link" onClick={handleShow} > Courses </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/#services-courses"> Courses </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/#about"> About </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#testimonial"> Testimonial </a>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/survey"> Survey </NavLink>
              </li> */}
              {userStore?.users?.user?.wallet &&
              <li className="nav-item">
                <NavLink className="nav-link" to="/post"> Post </NavLink>
              </li>
              }
              <li className="nav-item">
                {userStore?.users?.user?.name ?
                    <div className="logoutDrop">
                      <button className='default-btn name '>
                        {userStore?.users?.user?.name.length >12 ?userStore?.users?.user?.name?.slice(0,12)+" ..." : userStore?.users?.user?.name}
                      </button>
                      <div className="logout" onClick={logout}>Logout</div>
                    </div>
                :
                <>
                <div className="d-flex">
                  <NavLink className="nav-link me-0" to="/signup"> 
                      <button className='default-btn'>Register</button>
                  </NavLink>
                  <NavLink className="nav-link" to="/login"> 
                      <button className='default-btn'>Login</button>
                  </NavLink>
                </div>
                </>
                }
              </li>
            
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleClose}>
          <i className="fa-solid fa-xmark" />
          </div>
          <div className="couseModal">
            <div className="mainBtn">
              <div className={courseData[0]?.title == "Halal Haram Future Trading Ultimate Guide." ?'groupBtn ':'groupBtn active'} onClick={handleSetBasic}>For Beginner's</div>
              <div className={courseData[0]?.title == "Halal Haram Future Trading Ultimate Guide." ?'groupBtn active':'groupBtn '}  onClick={handleSetAdvance}>For Advanced</div>
            </div>
            <div className="linkM">
              {/* <a href={val?.link} target='_blank' className="link" key={key}>{val?.title}</a> */}
              {courseData.map((val,key)=> (
                <div className="accordion" id="accordionExample">
                  <div className="">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="link" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${key}`} aria-expanded="true" aria-controls={`collapseOne${key}`}>
                       {val?.title}
                      </button>
                    </h2>
                    {val?.link.split(",").length > 1 ?
                    <div id={`collapseOne${key}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        {val?.link.split(",").map((item,key1)=>(
                          <a href={item} key1={key1} target='_blank'>{item}</a>
                        ))}
                      </div>
                    </div>
                    : 
                    <div id={`collapseOne${key}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <a href={val?.link} target='_blank'>{val?.link}</a>
                      </div>
                    </div>
                    }
                  </div>
                  </div>
              ))}

            
            </div>
            {/* <div className="videoMain">
              {courseData.map((val,key)=> (
              <div className="video" key={key}>
                {val?.video}
              </div>
              ))}
            </div> */}
            {/* {courseData[0]?.video &&
            <div className='text-center'>
              <a className='default-btn' href="https://www.youtube.com/playlist?list=PLeYUkJPHvOtuiaCtvKTA5cKPqUPwYphLC" target="_blank" rel="noopener noreferrer">Playlist</a>
            </div>
            } */}
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal show={showYearly} onHide={handleCloseYearly} centered className='videoM'>
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleCloseYearly}>
          <i className="fa-solid fa-xmark" />
          </div>

          {/* <h2 className="title color-dark mb-0">To Join Yearly Group.</h2> */}
          <h5 className="color-dark text-center mb-4">Now you can pay in crypto.</h5>

          <p className='teather'>TETHER- USDT</p>
          <p className='teather'>NETWORK- TRC20 ADDRESS:</p>
          <p className='teather'>TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz 
            <span className='copyToClip' onClick={()=>handleCopy("TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz")}>
              <i className="fa-regular fa-copy ms-2 cursor-pointer"></i>
              <span>{copyText}</span>
            </span> 
          </p>
          <p className='teather mb-3'>PAYMENT: <span >999$</span></p>
          
          <div className="noteTitle">Note:</div>
          {/* <div className="closed">Membership is closed! </div>
          <div className="closed">Visit again </div> */}
          <div className="note">
            Do not send any amount except yearly amount; If you send so, the amount will not be refunded and this account is just for yearly $999 worth of usdt and again it is NOT Refundable. Send your CNIC front and back picture for KYC. If you are underage send your parents or school/college id card.
          </div>
          <div className="desc1">Once you done payment Please Send the TX id and do screen record while doing transaction then Whatsapp Message on
          <a href="https://wa.me/+923082507105">+923082507105 </a> send all details on WhatsApp no call will be entertained Office working hours</div>
          <div className="desc1">01:00 PM to 09:00 PM</div>
          <div className="desc1">Monday to Friday Only</div>
         
        </div>
      </Modal.Body>
    </Modal>

    <Modal  size="lg" show={showBroadcast} onHide={handleCloseBroadcast} centered>
      <Modal.Body>
        <div className="otpModalMain broadcastModal">
          <div className="closeIcon" onClick={handleCloseBroadcast}>
          <i className="fa-solid fa-xmark" />
          </div>
          
          <div className="broadCastIco"><img src={broadCastIco} alt="" /></div>
          <h3 className="broadTitle mb-0">Join our broadcast channels for exclusive crypto and stock insights, and track Waqar Zaka's investments.</h3>
          <div className="broadBtnMain">
            <a className='themeBtn' href="https://www.instagram.com/channel/AbbEEsvUoFTOHaPf/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-instagram"></i>
              Join Insta Broadcast
            </a>
            <a className='themeBtn' href="https://m.me/j/Abat_s17duf8Dm-5/" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-facebook"></i>
              Join FB Broadcast
            </a>
          </div> 
         
        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}
const courseData1 = [
  {
    title:"Halal Haram Future Trading Ultimate Guide.",
    link: "https://www.facebook.com/share/p/cg9zvwrmL8nXvnvt/" ,
  },
  {
    title:"Future trading k Liya Waqar Zaka kesay Coin select karta hai ? ",
    link: "https://www.facebook.com/share/p/14UxY1oWFB/" ,
  },
  {
    title:"What is a Exchange?",
    link: "https://www.facebook.com/waqarzaka/videos/597929235762486/?idorvanity=932060957279680" ,
  },
  {
    title:"What is wallet?",
    link: "https://www.facebook.com/groups/waqarzakatechnologymovementpakistan/permalink/1449362605549510/?app=fbl" ,
  },
  {
    title:"How to register at Bitget?",
    link: "https://fb.watch/vT1qIAl-Ha/" ,
  },
  {
    title:"How to transfer funds to Bitget?",
    link: "https://fb.watch/vT1vA0xc1g/" ,
  },
  {
    title:"How to do Spot,Futures and Demo trading?",
    link: "https://fb.watch/vT1wnaYy7V/" ,
  },
  {
    title:"How to calculate liquidation price?",
    link: "https://fb.watch/vT1xpbYFaP/" ,
  },
  {
    title:"How to use Bitget Support for any issue?",
    link: "https://fb.watch/vT1yai8SmR/" ,
  },
  {
    title:"Tradingview",
    link: "https://www.facebook.com/100025467665160/videos/765525544964558/" ,
  },
  {
    title:"Trading basics",
    link: "https://fb.watch/vT1BEenY1U/" ,
  },
  {
    title:"Portfolio Management",
    link: "https://fb.watch/vT1CSpTYhy/" ,
  },
  {
    title:"Candlesticks and Price action",
    link: "https://fb.watch/vT1DOi7nw-/" ,
  },
  {
    title:"Future trading and practice",
    link: "https://fb.watch/vT1EupRNK5/" ,
  },
  {
    title:"Support and Resistance",
    link: "https://fb.watch/vT1FdiXwEE/" ,
  },
  {
    title:"Chart patterns",
    link: "https://fb.watch/vT1FVf250S/https://fb.watch/vT1GYDslOX/,https://fb.watch/vT1HkiY2E0/,https://fb.watch/vT1HM9OxDL/,https://fb.watch/vT1IAJELNw/,https://fb.watch/vT1J0p4D8E/,https://fb.watch/vT1JuPgNGi/," ,
  },
  {
    title:"RSI",
    link: "https://fb.watch/vT1JRoAeho/" ,
  },
  {
    title:"ATR",
    link: "https://fb.watch/vT1KCESt-c/" ,
  },

 
]

const courseDataAdvance = [

  {
    title:"Blockchain lecture 1",
    link: "https://web.facebook.com/100006395763387/videos/3094046077485221/" ,
  },
  {
    title:"Blockchain lecture 2",
    link: "https://web.facebook.com/100006395763387/videos/540968923952342/" ,
  },
  {
    title:"Blockchain lecture 3",
    link: "https://web.facebook.com/100006395763387/videos/320038449799480/" ,
  },
  {
    title:"Blockchain lecture 4",
    link: "https://web.facebook.com/100006395763387/videos/967129147452588/" ,
  },
  {
    title:"Blockchain lecture 5",
    link: "https://web.facebook.com/100006395763387/videos/238134021456513/" ,
  },
  {
    title:"Blockchain lecture 6",
    link: "https://web.facebook.com/100006395763387/videos/889264058615571/" ,
  },
  {
    title:"Blockchain lecture 7",
    link: "https://web.facebook.com/100006395763387/videos/542432556939329/" ,
  },
  {
    title:"Blockchain lecture 8",
    link: "https://web.facebook.com/100006395763387/videos/370434361319733/" ,
  },
  {
    title:"Blockchain lecture 9",
    link: "https://web.facebook.com/100006395763387/videos/887865635415057/" ,
  },
  {
    title:"Blockchain lecture 10",
    link: "https://web.facebook.com/100006395763387/videos/887865635415057/" ,
  },
  {
    title:"Blockchain lecture 11",
    link: "https://fb.watch/w8ruhphOLH/" ,
  },
  {
    title:"Blockchain lecture 12",
    link: "https://fb.watch/w8rvY7sfsu/" ,
  },
  {
    title:"Blockchain lecture 13",
    link: "https://fb.watch/w8ryuUE2Cj/" ,
  },
  {
    title:"Blockchain lecture 14",
    link: "https://fb.watch/w8rzUBbiCe/" ,
  },
  {
    title:"Blockchain lecture 15",
    link: "https://fb.watch/w8rBIHz_WU/" ,
  },
  {
    title:"Blockchain lecture 16",
    link: "https://fb.watch/w8rDFZbZfI/" ,
  },
  {
    title:"Blockchain lecture 17",
    link: "https://fb.watch/w8rF43uEi1/" ,
  },
  {
    title:"Blockchain lecture 18",
    link: "https://fb.watch/w8rGNM8p7q/" ,
  },
  {
    title:"Blockchain lecture 19",
    link: "https://fb.watch/w8rIbtnnBn/" ,
  },
  {
    title:"Blockchain lecture 20",
    link: "https://fb.watch/w8rSnOqxf5/" ,
  },
  {
    title:"Blockchain lecture 21",
    link: "https://fb.watch/w8rUhlrBmY/" ,
  },
  {
    title:"Blockchain lecture 22",
    link: "https://fb.watch/w8rXALgwnI/" ,
  },
  {
    title:"Blockchain lecture 23",
    link: "https://fb.watch/w8rZ20wC2k/" ,
  },
  {
    title:"Blockchain lecture 24",
    link: "https://www.facebook.com/100025467665160/videos/572089615091603/" ,
  },
  {
    title:"Blockchain lecture 25",
    link: "https://fb.watch/w8s02kbKhR/" ,
  },
  {
    title:"Blockchain lecture 26",
    link: "https://fb.watch/w8s1qtTzMh/" ,
  },
  {
    title:"Blockchain lecture 27",
    link: "https://fb.watch/w8s2_XohJe/" ,
  },
  {
    title:"Blockchain lecture 28",
    link: "https://fb.watch/w8s51EIzsw/" ,
  },
  {
    title:"Blockchain lecture 29",
    link: "https://fb.watch/w8s6yD1RRD/" ,
  },
  {
    title:"Blockchain lecture 30",
    link: "https://fb.watch/w8s83nyNR2/" ,
  },
  {
    title:"Blockchain lecture 31",
    link: "https://fb.watch/w8s9r0Gk3E/" ,
  },
  {
    title:"Blockchain lecture 32",
    link: "https://www.facebook.com/100025467665160/videos/6175884379197536/" ,
  },
  {
    title:"Blockchain lecture 33",
    link: "https://fb.watch/w8sckP3Y2t/" ,
  },
  {
    title:"Blockchain lecture 34",
    link: "https://fb.watch/w8sdsuTQ7-/" ,
  },
  {
    title:"Blockchain lecture 35",
    link: "https://fb.watch/w8sf6pcUIq/" ,
  },
  {
    title:"Blockchain lecture 36",
    link: "https://www.facebook.com/100025467665160/videos/260925893409078/" ,
  },
  {
    title:"Blockchain lecture 37",
    link: "https://www.facebook.com/100025467665160/videos/313621651139133/" ,
  },
  {
    title:"Blockchain lecture 38",
    link: "https://www.facebook.com/100025467665160/videos/676281234105090/" ,
  },
  {
    title:"Blockchain lecture 39",
    link: "https://www.facebook.com/100025467665160/videos/638811621439542/" ,
  },
  {
    title:"Blockchain lecture 40",
    link: "https://www.facebook.com/100025467665160/videos/356648410070905/" ,
  },
  {
    title:"Blockchain lecture 41",
    link: "https://www.facebook.com/100025467665160/videos/727850806059197/" ,
  }, 
  {
    title:"Blockchain lecture 42",
    link: "https://www.facebook.com/100025467665160/videos/3656050548009025/" ,
  }, 
  {
    title:"Blockchain lecture 43",
    link: "https://www.facebook.com/100025467665160/videos/3656050548009025/" ,
  }, 
]



