import React, { useState } from 'react'
import profile from "../../../images/profile.png";
import editIco from "../../../images/editIco.svg";
import eyeICO from "../../../images/eyeICO.svg";
import arrowUp from "../../../images/arrowUp.svg";
import ethIco from "../../../images/ethIco.png";
import bnbIco from "../../../images/bnbIco.png";

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

export const PortfolioPage = ({setLoader,setWallet,ethData,bnbData,allwallets}) => {
  const navigate = useNavigate()
  const {activeWallet} = useParams()
  const [activeChain, setActiveChain] = useState('eth');
  const [activeData, setActiveData] = useState({});
  const {wallet} = useParams();
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();
 
    useEffect(() => {
     if(ethData?.ETH){
        setActiveData(ethData)
        setActiveChain('eth')
     }
     
    }, [ethData])

    const chainChange = (chain,data) => {
        setActiveChain(chain)
        setActiveData(data)
    }

    const AddWallet = async (wallet) => {
        // e.preventDefault();
        setLoader(true);
        try {
    
          const payload = {
            userID: userStore?.users?.user?._id,
            wallet: wallet.toLowerCase(),
          };
          const addResponse = await api.addWallet(payload);
        //   console.log(addResponse);
          
          if(addResponse?.success) {
                alert.show("Wallet Added")
                navigate(`/portfolio/dashboard/${wallet}`);
                setLoader(false);
                window.location.reload()
            }else{
              alert.error("Something went wrong")
          }
        
        } catch (e) {
          alert.show(e.message);
          // alert.show("Invalid Credentials or Account not verified");
          setLoader(false);
        }
      };

      const logingHandler = async ()=>{

        if (!window.ethereum){
          // alert('Install Metamask');
          alert.error("Install Metamask")
        } else{
        //   let web3 = new Web3(window.ethereum);
          let accs = await window.ethereum.request({
            method: 'eth_requestAccounts'
          });
          
          if (accs.length > 0){
              let ad = accs[0].toLowerCase();
              console.log(ad);
            let check = checkExistWallet(ad)
            if(check) {
                alert.show("Wallet Already Added");
                return
            }
            AddWallet(ad)
        
          }
        }
        // setisConnecting(false)
      }
      const checkExistWallet = (add)=> {
        if(allwallets.length > 0 ){
            let check =  allwallets.find(d => d.wallet.toLowerCase() == add)
            if(check?.wallet){
                return true
            }else{
                return false
            }         
        }else{
            return false
        }
      }

    useEffect(() => {
        if(wallet.length == 42){
            setWallet(wallet)
        }
    }, [wallet])
    
    
  return (
    <div className='portfolioPage'>
        <div className="selectChain">
            <button className={activeChain == "eth" ?"sBtn active":"sBtn"}  onClick={()=>chainChange("eth",ethData)}>Ethereum Chain</button>
            <button className={activeChain == "bnb" ?"sBtn active":"sBtn"}  onClick={()=>chainChange("bnb",bnbData)}>Binance Chain</button>
            <button className="sBtn active ms-auto" onClick={()=>logingHandler()}>Connect Wallet</button>
        </div>
        <div className="row">
            <div className="col-md-6 mb-3">
                <div className="portCard">
                    <div className="head">Wallets</div>
                    <div className="myPortForm">
                        <div className='mb-3'>
                            <div className="titleRow">
                                <div className="name">Meta Mask</div>
                                {/* <div className="ico"><img src={editIco} alt="" /></div> */}
                            </div>
                            {allwallets.map((item,key)=>(
                                <div className={activeWallet == item?.wallet ? "profileRow active":"profileRow"} key={key} onClick={()=>navigate(`/portfolio/dashboard/${item?.wallet}`)}>
                                    <div className="logo">
                                        {activeChain == "eth" ? 
                                            <img src={ethIco} alt="" />
                                            :
                                            <img src={bnbIco} alt="" />
                                        }                                
                                    </div>
                                    <div>
                                        <div className="title">{item?.wallet ? item?.wallet.slice(0,5)+"..."+ item?.wallet.slice(item?.wallet.length-4,item?.wallet.length)  : "Wallet 1" }</div>
                                        {/* <div className="amount">${ethData?.ETH ? (ethData?.ETH?.balance * ethData?.ETH?.price?.rate) :0}</div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div>
                            <div className="titleRow">
                                <div className="name">Trust Wallet</div>
                                <div className="ico"><img src={editIco} alt="" /></div>
                            </div>
                            <div className="profileRow">
                                <div className="logo"><img src={profile} alt="" /></div>
                                <div>
                                    <div className="title">My Portfolio</div>
                                    <div className="amount">$3,153,081.03</div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="addAccount">+ Add New account</div> */}

                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="portCard">
                    <div className="head">My Account</div>
                    <div className="myPortForm">
                        <div className="amountRow">
                            <div>
                                <div className="pRow">
                                    <div className="imgDiv">
                                        {activeChain == "eth" ? 
                                        <img src={ethIco} alt="" />
                                        :
                                        <img src={bnbIco} alt="" />
                                        }
                                    </div>
                                    My Main Portfolio
                                </div>
                                <div className="amount">${activeData?.ETH ? (activeData?.ETH?.balance * activeData?.ETH?.price?.rate).toFixed(4) :0}</div>
                                <div className="amountSm">{activeData?.ETH?.price?.diff > 0 && "+"} {activeData?.ETH ? activeData?.ETH?.price?.diff :0}% (24h)</div>
                            </div>
                            <div>
                                <img src={eyeICO} alt="" />
                            </div>
                        </div>
                        {/* <div className="r2">
                            <div className="profiltCard">
                                <div className="title">All-time profit</div>
                                <div className="amount">+ $6,232.30</div>
                                <div className="perc"><img src={arrowUp} alt="" /> 0.19%</div>
                            </div>
                            <div className="performerCard">
                                <div className="title">All-time profit</div>
                                <div className="currency">
                                    <img src={profile} alt="" />
                                    BTC
                                </div>
                                <div className="perc">+ $6,590.52 <img src={arrowUp} alt="" /> <span>0.21%</span></div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-3">
                <div className="portCard">
                    <div className="head">Portfolio</div>
                    <div className="myPortForm">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="balanceM">
                                    <div className="title">Your Balance in USD</div>
                                    <div className="balance">$ {activeData?.ETH ? (activeData?.ETH?.balance * activeData?.ETH?.price?.rate).toFixed(4) :0}</div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="tokenMain">
                                    {activeData?.tokens ?  activeData?.tokens.map((item,key)=>(
                                        <div className="tokenRow" key={key}>
                                            <div className="coin">
                                                {/* <div className="logo">
                                                    <img src={btc} alt="" />
                                                </div> */}
                                                {item?.tokenInfo?.name ? item?.tokenInfo?.name :"name"}
                                            </div>
                                            <div>
                                                <div className="amount">{item?.balance > 0 ? Math.round((item?.balance / (item?.tokenInfo?.decimals ? (10 ** parseInt(item?.tokenInfo?.decimals)) : 1))) : 0}</div>
                                                <div className="usd">${(item?.tokenInfo?.price?.rate * item?.tokenInfo?.balance || 0).toFixed(2)}</div>
                                            </div>
                                        </div>
                                    )) : <h3>Do not own any token.</h3> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
